<template>
  <div class="preloader flex justify-center">
    <div class="preloader__spinner-container">
      <div class="preloader__spinner">
        <div class="preloader__circle-clipper preloader__circle-clipper_left">
          <div class="preloader__circle" />
        </div>
        <div class="preloader__circle-clipper preloader__circle-clipper_right">
          <div class="preloader__circle" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

$size: 40px;

.preloader {
  margin: $size 0;

  &__circle {
    border: 3px solid variables.$main-green-color;
    border-bottom-color: transparent;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 200%;
  }

  &__circle-clipper {
    display: inline-block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 50%;

    &_left .preloader__circle {
      animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      border-right-color: transparent;
      left: 0;
      transform: rotate(129deg);
    }

    &_right .preloader__circle {
      animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      border-left-color: transparent;
      left: -100%;
      transform: rotate(-129deg);
    }
  }

  &__spinner {
    animation: spinner-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__spinner-container {
    animation: spinner-container-rotate 1568.24ms linear infinite;
    height: $size;
    position: relative;
    width: $size;
  }
}

@keyframes left-spin {
  0% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(130deg);
  }
}

@keyframes right-spin {
  0% {
    transform: rotate(-130deg)
  }
  50% {
    transform: rotate(5deg)
  }
  100% {
    transform: rotate(-130deg)
  }
}

@keyframes spinner-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes spinner-container-rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
